// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import './assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import 'node_modules/primeng/resources/primeng.min.css';
@import 'node_modules/primeflex/primeflex.scss';
@import 'node_modules/primeicons/primeicons.css';
.background-image {
  background: url('./assets/layout/images/card.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.p-dropdown {
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}
